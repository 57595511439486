import React from 'react';
import './styles.scss';
import { ButtonProps } from './types';

const Button: React.FC<ButtonProps> = ({ href, fullWidth, children }) => {
  return (
    <a
      href={href}
      className={`button ${fullWidth ? 'full-width' : ''}`}
      target='_blank'
      rel='noopener noreferrer'
    >
      {children}
    </a>
  );
};

export default Button;
