import React, { useState, useEffect } from 'react';
import App from '../components/App/component';
import Loader from '../components/loader/component';

interface DataItem {
  _lineNumber: number;
  Proyecto: string;
  'Nombre de archivo': string;
  'Año creacion': string;
  'Archivo de destino'?: string;
  Estado?: string;
  'Link original'?: string;
  'Link figma destino'?: string;
}

const AppContainer: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const [filteredData, setFilteredData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_API_URL!, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
      });
      const result = await response.json();
      setData(result);
      setFilteredData(result);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleFilter = (filtered: DataItem[]) => {
    setFilteredData(filtered);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <App data={data} filteredData={filteredData} onFilter={handleFilter} />
  );
};

export default AppContainer;
