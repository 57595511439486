import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import AppContainer from './containers/AppContainer';


const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AppContainer />
  </React.StrictMode>,
);
