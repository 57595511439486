// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.filter-button-group .filter-button {
  display: inline-block;
  margin: 5px;
  padding: 10px 20px;
  background-color: #4a4a4a;
  color: #f0f0f0;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Roboto", sans-serif;
}
.filter-button-group .filter-button.selected {
  background-color: #abaaaa;
  color: #f0f0f0;
}
.filter-button-group .filter-button:hover {
  background-color: rgb(99.5, 99.5, 99.5);
}`, "",{"version":3,"sources":["webpack://./src/components/filter/styles.scss","webpack://./src/styles/vars.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,uBAAA;EACA,gBAAA;EACA,mBAAA;AADF;AAGE;EACE,qBAAA;EACA,WAAA;EACA,kBAAA;EACA,yBCVc;EDWd,cCbe;EDcf,YAAA;EACA,kBAAA;EACA,eAAA;EACA,iCCVU;ADSd;AAGI;EACE,yBCfW;EDgBX,cCrBa;ADoBnB;AAII;EACE,uCAAA;AAFN","sourcesContent":["@import '../../styles/vars.scss';\n\n.filter-button-group {\n  display: flex;\n  justify-content: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n\n  .filter-button {\n    display: inline-block;\n    margin: 5px;\n    padding: 10px 20px;\n    background-color: $secondary-color;\n    color: $background-color;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    font-family: $font-family;\n\n    &.selected {\n      background-color: $selected-color;\n      color: $background-color;\n    }\n\n    &:hover {\n      background-color: lighten($secondary-color, 10%);\n    }\n  }\n}\n","$background-color: #f0f0f0;\n$primary-color: #333333;\n$secondary-color: #4a4a4a;\n$text-color: #000000;\n$accent-color: #fff;\n$selected-color: #abaaaa;\n\n$font-family: 'Roboto', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
