// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  width: 100%;
  display: inline-block;
  padding: 10px 20px;
  background-color: #333333;
  color: #f0f0f0;
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
  font-size: 14px;
}
.button:hover {
  background-color: rgb(25.5, 25.5, 25.5);
}
.button.full-width {
  flex: none;
  width: calc(100% - 40px);
}`, "",{"version":3,"sources":["webpack://./src/components/button/styles.scss","webpack://./src/styles/vars.scss"],"names":[],"mappings":"AAIA;EACE,WAAA;EACA,qBAAA;EACA,kBAAA;EACA,yBCPc;EDQd,cCTiB;EDUjB,kBAAA;EACA,kBAAA;EACA,qBAAA;EACA,iBAAA;EACA,iCAAA;EACA,eAAA;AAHF;AAKE;EACE,uCAAA;AAHJ;AAME;EACE,UAAA;EACA,wBAAA;AAJJ","sourcesContent":["// styles.scss\n\n@import '../../styles/vars.scss';\n\n.button {\n  width: 100%;\n  display: inline-block;\n  padding: 10px 20px;\n  background-color: $primary-color;\n  color: $background-color;\n  text-align: center;\n  border-radius: 5px;\n  text-decoration: none;\n  font-weight: bold;\n  transition: background-color 0.3s;\n  font-size: 14px;\n\n  &:hover {\n    background-color: darken($primary-color, 10%);\n  }\n\n  &.full-width {\n    flex: none;\n    width: calc(100% - 40px);\n  }\n}\n","$background-color: #f0f0f0;\n$primary-color: #333333;\n$secondary-color: #4a4a4a;\n$text-color: #000000;\n$accent-color: #fff;\n$selected-color: #abaaaa;\n\n$font-family: 'Roboto', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
