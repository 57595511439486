// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
  background: #fff;
  border: 1px solid #4a4a4a;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card .card-header {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 10px;
}
.card .card-header h2 {
  margin: 0;
  font-size: 1.1rem;
  color: #000000;
  flex: 1 1;
}
.card .card-header .card-meta {
  font-size: 0.875rem;
  color: #333333;
  text-align: right;
  white-space: nowrap;
  margin-left: 10px;
}
.card p {
  margin: 10px 0;
}
.card p strong {
  color: #333333;
}
.card .card-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/card/styles.scss","webpack://./src/styles/vars.scss"],"names":[],"mappings":"AAGA;EACE,gBAAA;EACA,yBAAA;EACA,kBAAA;EACA,aAAA;EACA,uCAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AAFF;AAIE;EACE,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,mBAAA;AAFJ;AAII;EACE,SAAA;EACA,iBAAA;EACA,cCpBO;EDqBP,SAAA;AAFN;AAKI;EACE,mBAAA;EACA,cC5BU;ED6BV,iBAAA;EACA,mBAAA;EACA,iBAAA;AAHN;AAOE;EACE,cAAA;AALJ;AAMI;EACE,cCtCU;ADkChB;AAQE;EACE,aAAA;EACA,SAAA;EACA,gBAAA;AANJ","sourcesContent":["// styles.scss\n@import '../../styles/vars.scss';\n\n.card {\n  background: $accent-color;\n  border: 1px solid $secondary-color;\n  border-radius: 5px;\n  padding: 20px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  text-align: left;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n\n  .card-header {\n    display: flex;\n    justify-content: space-between;\n    align-items: start;\n    margin-bottom: 10px;\n\n    h2 {\n      margin: 0;\n      font-size: 1.1rem;\n      color: $text-color;\n      flex: 1; // Allow the title to take available space\n    }\n\n    .card-meta {\n      font-size: 0.875rem;\n      color: $primary-color;\n      text-align: right;\n      white-space: nowrap; // Prevent the text from wrapping to a new line\n      margin-left: 10px;\n    }\n  }\n\n  p {\n    margin: 10px 0;\n    strong {\n      color: $primary-color;\n    }\n  }\n\n  .card-buttons {\n    display: flex;\n    gap: 10px;\n    margin-top: 10px;\n  }\n}\n","$background-color: #f0f0f0;\n$primary-color: #333333;\n$secondary-color: #4a4a4a;\n$text-color: #000000;\n$accent-color: #fff;\n$selected-color: #abaaaa;\n\n$font-family: 'Roboto', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
