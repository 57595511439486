import React from 'react';
import './styles.scss';
import Button from '../button/component';
import { CardProps } from './types';

const Card: React.FC<CardProps> = ({
  name,
  project,
  year,
  destination,
  status,
  originalLink,
  destinationLink,
}) => {
  return (
    <div className='card'>
      <div className='card-header'>
        <h2>{name}</h2>
        <div className='card-meta'>
          <span>
            {project} {year}
          </span>
        </div>
      </div>
      {destination && (
        <p>
          <strong>Archivo de destino:</strong> {destination}
        </p>
      )}
      {status && (
        <p>
          <strong>Estado:</strong> {status}
        </p>
      )}
      <div className='card-buttons'>
        {originalLink && originalLink !== '--' && (
          <Button
            href={originalLink}
            fullWidth={!destinationLink || destinationLink === '--'}
          >
            Figma original
          </Button>
        )}
        {destinationLink && destinationLink !== '--' && (
          <Button
            href={destinationLink}
            fullWidth={!originalLink || originalLink === '--'}
          >
            Figma destino
          </Button>
        )}
      </div>
    </div>
  );
};

export default Card;
