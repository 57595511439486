import React, { useEffect, useState } from 'react';
import './styles.scss';
import { FilterProps } from './types';

const FilterButtonGroup: React.FC<FilterProps> = ({
  data,
  filterKey,
  onFilter,
}) => {
  const [filterValues, setFilterValues] = useState<string[]>([]);
  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  useEffect(() => {
    const uniqueValues = [...new Set(data.map((item) => item[filterKey]))]
      .filter((value) => !isNaN(value) || value !== '')
      .sort();
    setFilterValues(uniqueValues);
  }, [data, filterKey]);

  const handleValueClick = (value: string) => {
    const newSelectedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value)
      : [...selectedValues, value];
    setSelectedValues(newSelectedValues);
    filterData(newSelectedValues);
  };

  const filterData = (values: string[]) => {
    const filtered = data.filter(
      (item) => values.length === 0 || values.includes(item[filterKey]),
    );
    onFilter(filtered);
  };

  if (filterValues.length === 0) {
    return null;
  }

  return (
    <div className='filter-button-group'>
      {filterValues.map(
        (value) =>
          value !== '' && (
            <button
              key={value}
              className={`filter-button ${
                selectedValues.includes(value) ? 'selected' : ''
              }`}
              onClick={() => handleValueClick(value)}
            >
              {value}
            </button>
          ),
      )}
    </div>
  );
};

export default FilterButtonGroup;
