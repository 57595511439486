import React, { useState } from 'react';
import './App.scss';
import Card from '../card/component';
import Filter from '../filter/component';
import { AppProps } from './types';

const App: React.FC<AppProps> = ({ data, filteredData, onFilter }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    filterData(term);
  };

  const filterData = (term: string) => {
    const filtered = data.filter(
      (item) =>
        item.Proyecto.toLowerCase().includes(term) ||
        item['Nombre de archivo'].toLowerCase().includes(term) ||
        item['Año creacion'].toLowerCase().includes(term),
    );
    onFilter(filtered);
  };

  return (
    <div className='App'>
      <h1>Filtrar Proyectos</h1>
      <input
        type='text'
        placeholder='Buscar por proyecto, año, etc...'
        value={searchTerm}
        onChange={handleSearch}
      />
      <Filter data={data} filterKey='Año creacion' onFilter={onFilter} />
      <Filter data={data} filterKey='Proyecto' onFilter={onFilter} />
      <div className='grid'>
        {filteredData
          .filter((item) => item['Nombre de archivo'])
          .map((item) => (
            <Card
              key={item._lineNumber}
              name={item['Nombre de archivo']}
              project={item.Proyecto}
              year={item['Año creacion']}
              destination={item['Archivo de destino']}
              status={item.Estado}
              originalLink={item['Link original']}
              destinationLink={item['Link figma destino']}
            />
          ))}
      </div>
    </div>
  );
};

export default App;
