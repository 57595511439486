// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  background-color: #f0f0f0;
  color: #000000;
  font-family: "Roboto", sans-serif;
}

.App {
  text-align: center;
  padding: 20px;
}
.App h1 {
  color: #333333;
}
.App input,
.App select {
  padding: 10px;
  margin-bottom: 20px;
  width: 300px;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
}
.App input:focus,
.App select:focus {
  outline: none;
  border-color: #333333;
}
.App select {
  margin-left: 10px;
}
.App .grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}
.App .loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}`, "",{"version":3,"sources":["webpack://./src/components/App/App.scss","webpack://./src/styles/vars.scss"],"names":[],"mappings":"AAGA;EACE,yBCJiB;EDKjB,cCFW;EDGX,iCCCY;ADHd;;AAKA;EACE,kBAAA;EACA,aAAA;AAFF;AAIE;EACE,cCbY;ADWhB;AAKE;;EAEE,aAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;EACA,kBAAA;EACA,eAAA;EACA,sBCrBW;ADkBf;AAKI;;EACE,aAAA;EACA,qBC5BU;AD0BhB;AAME;EACE,iBAAA;AAJJ;AAOE;EACE,aAAA;EACA,2DAAA;EACA,SAAA;EACA,gBAAA;AALJ;AAQE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AANJ","sourcesContent":["// Define la paleta de colores\n@import '../../styles/vars';\n\nbody {\n  background-color: $background-color;\n  color: $text-color;\n  font-family: $font-family;\n}\n\n.App {\n  text-align: center;\n  padding: 20px;\n\n  h1 {\n    color: $primary-color;\n  }\n\n  input,\n  select {\n    padding: 10px;\n    margin-bottom: 20px;\n    width: 300px;\n    border: 1px solid $text-color;\n    border-radius: 5px;\n    font-size: 16px;\n    background-color: $accent-color;\n\n    &:focus {\n      outline: none;\n      border-color: $primary-color;\n    }\n  }\n\n  select {\n    margin-left: 10px;\n  }\n\n  .grid {\n    display: grid;\n    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));\n    gap: 20px;\n    margin-top: 20px;\n  }\n\n  .loader-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 80vh; // Centrar el loader verticalmente\n  }\n}\n","$background-color: #f0f0f0;\n$primary-color: #333333;\n$secondary-color: #4a4a4a;\n$text-color: #000000;\n$accent-color: #fff;\n$selected-color: #abaaaa;\n\n$font-family: 'Roboto', sans-serif;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
